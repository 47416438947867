<template>
    <v-row >
      กำลังเข้าสู่ระบบ
    </v-row>
    
</template>

<script>


  export default {
    
  name: 'TokenShow',
  
  data(){
    return {
      token : localStorage.getItem("cmuMobileToken"),
    }
  },
  created(){
    this.getProfileOauth()
  },
 
  methods:{
    async getProfileOauth(){
      this.$router.push({ path: "/" })
    }

  }
  
}
</script>